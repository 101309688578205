
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import ContactUs from '@/components/contactUs/index.vue'
import {config} from "@/utils"
export default {
    name: 'online',
    components:{
        Header,
        Footer,
        ContactUs
    },
    mounted () {
    },
    created(){

    },
    methods: {
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        }
    },

    data(){
        return{
            labList:[
                {
                    img:require('./images/team1.png'),
                    name:'李治国',
                    able:'擅长：知识产权法、公司法、金融证券、投融资。',
                    blurb:'中国版权协会理事，广东省知识产权局知识产权专家，广州律协版权委副主任。美国伊利诺伊理工大学，法学硕士。',
                    bot:'立即咨询'
                },
                {
                    img:require('./images/team2.png'),
                    name:'黄胜友',
                    able:'擅长：知识产权维权、民商事诉讼、企业法律顾问。',
                    blurb:'国家版权贸易基地（越秀） 维权专家。 西南政法大学，法律硕士研究生。',
                    bot:'立即咨询'
                },
                {
                    img:require('./images/team3.png'),
                    name:'刘杨',
                    able:'擅长：知识产权法、公司法、金融证券、投融资。',
                    blurb:'国家商标品牌创新创业（广州）基地维权专家委员会委员。西南政法大学，法学理论硕士，研究生。',
                    bot:'立即咨询'
                },
                {
                    img:require('./images/team4.png'),
                    name:'韦海雁',
                    able:'擅长：专利申请、专利无 效、科技项目培育、版...',
                    blurb:'第二届省知识产权保护协会 专家。 毕业于大连海事大学。',
                    bot:'立即咨询'
                },
               

            ]


        }
        
    }
    
}